import React, { useEffect, useState } from "react";
import logo from "../../../img/logo.svg";
import { NavDropdown, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import {
  getAllNotification,
  getNotification,
  markAsRead,
} from "../../../redux/actions/notification.action";
import { timeAgo } from "../../../utils/core";
import { SET_NEW_NOTIFICATION } from "../../../redux/constants/notificationTypes";
import ConfirmationPopup from "../Popup/ConfirmationPopup";
const TopNavBar = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.auth.userProfile);
  const allNotifications = useSelector(
    (state) => state.notification.notifications
  );
  const mainNotifications = useSelector(
    (state) => state.notification.notificationList
  );
  const notificationCount = useSelector(
    (state) => state.notification.notificationCount
  );
  const [showPopup, setShowPopup] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const user = {
    user_id: userProfile?._id,
    name: userProfile?.firstName + " " + userProfile?.lastName,
  };

  // useEffect(() => {
  //   setNotifications(allNotifications.slice(0, 5));
  // }, [allNotifications]);
  useEffect(() => {
    setNotifications(mainNotifications);
  }, [mainNotifications]);

  useEffect(() => {
    // if (userProfile._id) {
    //   // const socket = io(`http://13.235.161.103:7000`);
    //   // const socket = io(`http://65.0.227.65:7000/notifications`);
    //   const socket = io(`https://api.surgicshare.com/notifications`);
    //   socket.emit("setup", user);
    //   socket.on("connect", () => {
    //     console.log("Connected to server", socket);
    //   });
    //   socket.on("newNotification", (data) => {
    //     console.log("New notification received:", data);
    //     dispatch({ type: SET_NEW_NOTIFICATION, payload: data });
    //   });
    //   return () => {
    //     socket.disconnect();
    //   };
    // }
  }, [userProfile]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getAllNotification({ page: 1 }, true));
    dispatch(getNotification({}));
  }, []);

  let dropdownArray = [
    {
      title: "CME",
      routeTo: "",
      dropDownItems: [
        {
          title: "Attend CME (Continuing Medical Education)",
          routeTo: "/attendCmeRtm",
        },
        {
          title: "Schedule Live CME (Continuing Medical Education)",
          routeTo: "/scheduleLiveCmeRtm",
        },
        {
          title: "My Uploaded CME",
          routeTo: "/myCmeRtm",
        },
      ],
    },
    // {
    //   title: (
    //     <>
    //       Live
    //       <br className="d-none d-lg-block" /> Surgery
    //     </>
    //   ),
    //   routeTo: "",
    //   dropDownItems: [
    //     {
    //       title: "Browse Live Surgery",
    //       routeTo: "/browseLiveSurgery",
    //     },
    //     {
    //       title: "Attend Live Surgery",
    //       routeTo: "/attendLiveSurgery",
    //     },
    //     {
    //       title: "Organize Live Surgery",
    //       routeTo: "/organizeLiveSurgery",
    //     },
    //     {
    //       title: "My Upload of Live Surgery",
    //       routeTo: "/myLiveSurgery",
    //     },
    //   ],
    // },
    {
      title: (
        <>
          Surgery
          <br className="d-none d-lg-block" /> Videos
        </>
      ),
      routeTo: "",
      dropDownItems: [
        {
          title: "Browse Surgery Videos",
          routeTo: "/browseSurgeryVideos",
        },
        {
          title: "Upload Surgery Videos",
          routeTo: "/uploadSurgeryVideos",
        },
        {
          title: "My Uploaded Surgery Videos",
          routeTo: "/mySurgeryVideos",
        },
      ],
    },
    {
      title: "Tutorial",
      routeTo: "",
      dropDownItems: [
        {
          title: "Browse Tutorials",
          routeTo: "/browseTutorials",
        },
        {
          title: "Upload Tutorial",
          routeTo: "/uploadTutorials",
        },
        {
          title: "My Uploaded Tutorials",
          routeTo: "/myTutorials",
        },
      ],
    },
    {
      title: "Observership",
      routeTo: "",
      dropDownItems: [
        {
          title: "Browse Observership Course",
          routeTo: "/browseObservership",
        },
        // {
        //   title: "Attend Observership Course",
        //   routeTo: "/attendObservership",
        // },
        {
          title: "Post Observership Course",
          routeTo: "/postObservership",
        },
        {
          title: "My Uploaded Observership Courses",
          routeTo: "/myObservership",
        },
      ],
    },
    {
      title: (
        <>
          Conference &<br className="d-none d-lg-block" /> Workshop
        </>
      ),
      routeTo: "",
      dropDownItems: [
        {
          title: "Browse Conference, Workshop and Hands on Training",
          routeTo: "/browseConfWorkshop",
        },
        // {
        //   title: "Attend Conference, Workshop and Hands on Training",
        //   routeTo: "/attendConfWorkshop",
        // },
        {
          title: "Post Conference, Workshop and Hands on Training",
          routeTo: "/postConfWorkshop",
        },
        {
          title: "My Upload of Conference/ Workshop / Hands on training",
          routeTo: "/myConfWorkshop",
        },
      ],
    },
    {
      title: "Patient Referral",
      routeTo: "",
      dropDownItems: [
        {
          title: "Check Availability of Surgeons",
          routeTo: "/referYourPatient",
        },
        {
          title: "Create Your Customized Profile",
          routeTo: "/create-patient-referral",
        },
        {
          title: "Patients I Referred",
          routeTo: "/referredPatient",
        },
        {
          title: "Patient Referrals Received",
          routeTo: "/referralReceived",
        },
      ],
    },
    {
      title: (
        <>
          Associations &<br className="d-none d-lg-block" /> Groups
        </>
      ),
      routeTo: "",
      dropDownItems: [
        {
          title: "Browse Associations and Groups",
          routeTo: "/BrowseAssocGroups",
        },
        {
          title: "My Associations and Groups",
          routeTo: "/myAssocGroups",
        },
        {
          title: "Create Association or Group",
          routeTo: "/createAssocGroups",
        },
      ],
    },
    {
      title: "Telementorship",
      routeTo: "",
      dropDownItems: [
        {
          title: "Request Telementorship",
          routeTo: "/requestTelementorship",
        },
        // {
        //   title: "Requested Telementorship",
        //   routeTo: "/requestedTelementorship",
        // },
        // {
        //   title: "Approve Telementorship",
        //   routeTo: "/approveTelementorship",
        // },
      ],
    },
  ];
  let navigate = useNavigate();

  const handleNotificationClick = (val) => {
    dispatch(markAsRead({ id: val._id }));
    if (val.type === "VIDEOS") {
      navigate(`/video-details/${val.to_id}`);
    } else if (val.type === "TUTORIAL") {
      navigate(`/tutorial-details/${val.to_id}`);
    } else if (val.type === "CONFERENCE") {
      navigate(`/confDetails/${val.to_id}`);
    } else if (val.type === "MEETING") {
      navigate(`/cme-rte/${val.to_id}`);
    }
    window.location.reload();
  };

  const [isIconBurger, setIsIconBurger] = useState(true);

  const toggleIcon = () => {
    setIsIconBurger(!isIconBurger);
  };

  return (
    <header className="dash-header-wrap">
      {/* <Navbar
        collapseOnSelect
        expand="xl"
        className="fixed-top bg-white shadow"
      > */}
      <nav className="navbar navbar-expand-xl fixed-top bg-white shadow">
        <div className="container-fluid">
          <a href="javascript:history.back()" class="d-inline-block d-lg-none">
            <i
              class="fa fa-angle-left"
              style={{ fontSize: "20px", paddingRight: "16px" }}
              aria-hidden="true"
            ></i>
            {/* <i class="fas fa-arrow-left"></i> */}
            {/* <span class="svg-icon svg-icon-primary svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                <g fill="none" fill-rule="evenodd">
                  <path d="M0 0h24v24H0z"/>
                  <path fill="#000" fill-rule="nonzero" d="M15.707 6.707a1 1 0 1 0-1.414-1.414l-6 6a1 1 0 0 0-.03 1.383l5.5 6a1 1 0 1 0 1.474-1.352l-4.853-5.294 5.323-5.323Z"/>
                </g>
              </svg>
            </span> */}
          </a>

          <a
            onClick={() => navigate("/dashboard")}
            className="navbar-brand"
            href="javascript:void(0)"
          >
            <img
              src={logo}
              alt="Logo"
              style={{ width: "100px" }}
              className="nav-logo"
            />
          </a>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="ki-solid ki-burger-menu-5 text-primary"></i>
          </button> */}

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleIcon}
          >
            {isIconBurger ? (
              <i className="ki-solid ki-burger-menu-5 text-primary"></i>
            ) : (
              // <i className="ki-solid ki-close-circle text-primary"></i>
              <i class="fa fa-times" aria-hidden="true"></i>
            )}
          </button>

          <div className="navbar-right order-xl-3">
            <div className="profile-panel btn-group no-arrow-dropdown">
              <div className="notification-panel btn-group align-self-center btn-group">
                <NavDropdown
                  className=""
                  title={
                    <a
                      href="#notification-panel"
                      className="p-2 me-3 text-decoration-none notification-icon position-relative"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      alt=""
                      title="Notifications"
                    >
                      <i className="fa-solid fa-bell fs-5"></i>
                      {/* <span className="bell-circle position-absolute">
                      </span> */}
                      {notificationCount > 0 && (
                        <div className="badge bg-info badge bg-danger p-1">
                          {notificationCount}
                        </div>
                      )}
                    </a>
                  }
                >
                  <NavDropdown.Header className="h6 text-muted">
                    Notifications{" "}
                    <span className="badge bg-success">
                      {notificationCount}
                    </span>
                  </NavDropdown.Header>

                  <div className="notification-desc-wrap">
                    {notifications.map((val, index) => (
                      <NavDropdown.Item
                        key={index}
                        className={`py-2 ${
                          val.read_status === 0 ? "bg-light" : ""
                        }`}
                        onClick={() => handleNotificationClick(val)}
                      >
                        <div className="d-flex align-items-center">
                          <i className="fa-solid fa-bell"></i>
                          <div className="px-3">
                            <p className="mb-0 lh-sm d-block w-100">
                              {val.title}
                            </p>
                            <span className="text-muted d-block w-100">
                              {val.description}
                            </span>
                            <small className="mb-0 lh-1 text-muted fw-normal w-100 d-block">
                              {timeAgo(val.createdAt)}
                            </small>
                          </div>
                        </div>
                      </NavDropdown.Item>
                    ))}
                  </div>
                  {notificationCount > 5 ? (
                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/notification");
                      }}
                    >
                      View More
                    </NavDropdown.Item>
                  ) : null}
                </NavDropdown>
              </div>

              <NavDropdown
                className=""
                title={
                  <a
                    href="#my-profile"
                    className="text-decoration-none user-image-wrap"
                    title="My Profile"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userProfile.profilePicture ? (
                      <img
                        src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${userProfile.profilePicture}`}
                        alt=""
                        title="My Profile"
                      />
                    ) : (
                      <img
                        src={require("../../../img/user.png")}
                        alt=""
                        title="My Profile"
                      />
                    )}
                  </a>
                }
              >
                <NavDropdown.Item
                  onClick={() => {
                    navigate("/my-profile");
                  }}
                >
                  My Profile
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    setShowPopup(true);
                  }}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>

          <div
            className="collapse navbar-collapse pt-2 pt-xl-0"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              {/* <Nav.Item className=" d-block d-xl-none d-xxl-block">
                <Nav.Link eventKey="3">
                  <Link to={"/dashboard"}>Dashboard</Link>
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Link eventKey="3">
                <Link to={"/dashboard"} style={{ fontSize: "95%" }}>
                  Dashboard
                </Link>
              </Nav.Link>
              {dropdownArray.map((arr, arrIndex) => (
                <NavDropdown key={"drop_" + arrIndex} title={arr.title}>
                  {arr.dropDownItems.map((items, itemIndex) => (
                    <NavDropdown.Item
                      onClick={() => navigate(items.routeTo)}
                      key={"item_" + itemIndex}
                      style={{ fontSize: "95%" }}
                    >
                      {items.title}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ))}
              <Nav.Link eventKey="3">
                <Link to={"/support"} style={{ fontSize: "95%" }}>
                  Contact Us
                </Link>
              </Nav.Link>
            </ul>
          </div>
        </div>
      </nav>
      <ConfirmationPopup
        show={showPopup}
        message="Are you sure you want to log out? You can always log back in when needed."
        header="Confirm Logout ?"
        onConfirm={() => {
          localStorage.clear();
          navigate("/login");
        }}
        onCancel={() => {
          setShowPopup(false);
        }}
      />
    </header>
  );
};

export default TopNavBar;
