import React, { useEffect, useState } from "react";
import Root from "../components/Root";
import Breadcrumb from "./Breadcrumb/Breadcrumb";
import ContainerWrapper from "../layouts/ContainerWrapper";
import PublicRoot from "./PublicRoot";
import { useNavigate, Link } from "react-router-dom";
// import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { getAllGalleries } from "../../redux/actions/home.action";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-bootstrap";
import "../../globalstyles/css/lightbox.css";
import "../../globalstyles/js/lightbox.js";

const Home = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const galleryList = useSelector((state) => state.home.galleryList);
  let galeryArray = [];

  useEffect(() => {
    dispatch(getAllGalleries({ page: 1 }));
  }, []);

  if (galleryList.length > 0) {
    //  galeryArray = galleryList.slice(0, 6);
    galeryArray = galleryList;
  }

  const chunkSize = 5;
  const chunks = [];

  // for (let i = 0; i < galeryArray.length; i += chunkSize) {
  //   chunks.push(galeryArray.slice(i, i + chunkSize));
  // }

  // const dummyArray =[1,2,3,4,5]
  return (
    <PublicRoot>
      <>
        <section class="py-5 bg-primary">
          <div class="container">
            <div class="row d-flex align-items-center">
              <div class="col-12 col-lg-6 col-xl-5 order-2 order-lg-1">
                <div className="d-flex justify-content-between align-items-center">
                  <p class="title-meta mt-0">
                    About Us <span class="line"></span>
                  </p>

                  <a
                    href="javascript:void(0)"
                    title="Register"
                    className="btn btn-success mb-2 mt-2 btn-sm d-block d-lg-none" // Show on mobile, hide on larger screens
                    onClick={() => navigate("/register")}
                  >
                    Register Now <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>

                <h1 class="text-white mb-4 display-6">
                  Discover the amazing and patented world of{" "}
                  <strong>learning</strong> &{" "}
                  <strong>sharing medical and surgical skills</strong>
                </h1>
                <p class="text-white mb-4 d-block">
                  {/* Surgic Share is Patented digital academic platform for expert,
                  young and future surgeons for learning & sharing surgical
                  skills through organizing & attending CME & Live surgery on
                  Zoom, posting and getting updated with surgery videos,
                  tutorials, Conference/Workshop/hands on training &
                  Observership course and many more... */}
                  <span style={{ color: "red" }}>
                    The Annual Conference of the{" "}
                    <strong>
                      <span style={{ fontSize: "24px" }}>
                        Association of Physicians of India (APICON) 2025
                      </span>
                    </strong>{" "}
                    will be broadcast live on SURGIC SHARE from January 23 to
                    January 26, 2025, between 9:00 AM and 7:00 PM.
                  </span>
                </p>
                {/* <a
                  href="javascript:void(0)"
                  title="Read More"
                  class="btn btn-link btn-lg text-white mb-2"
                  onClick={() => navigate("/about")}
                >
                  Read More <i class="fa-solid fa-arrow-right"></i>
                </a> */}
                <a
                  href="javascript:void(0)"
                  title="Sign Up"
                  class="btn btn-success mb-2 btn-lg"
                  onClick={() => navigate("/register")}
                >
                  Register <i class="fa-solid fa-arrow-right"></i>
                </a>
              </div>
              <div class="col-12 col-lg-6 col-xl-7 order-1 order-lg-2">
                <img
                  src={require("../../img/hero-image.png")}
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="py-5">
          <div class="container">
            <div class="row">
              <div class="col-12">
                {/* <p class="title-meta mt-0 mb-0">
                  Specialities <span class="line"></span>
                </p> */}
                <h2 class="h3 mb-2">
                  Eminent Surgeons across surgical specialty
                </h2>
                {/* <p>Explore specialities and communities we cater to.</p> */}
              </div>
            </div>
            <div class="row d-flex align-items-stretch ">
              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/general-surgery.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">General Surgery </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img src={require("../../img/obg.png")} alt="" width="44" />
                    <h6 class="mt-3 mb-0">OBG </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/ortho.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Orthopedics </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/neuro.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Neuro Surgery </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/cardiac.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Cardiac Surgery </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/urology.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Urology </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/paediactric.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Paediatric Surgery </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/gastro.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Gastro Surgery </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/onco.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">ONCO Surgery </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/plastic.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Plastic Surgery </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img src={require("../../img/ent.png")} alt="" width="44" />
                    <h6 class="mt-3 mb-0">ENT </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/dental.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Dental Surgery </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch offset-xl-2">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/ophthalmology.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Ophthalmology </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/maxillofacial.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Maxillofacial Surgery </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/anesthesiology.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Anesthesiology </h6>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-4 col-lg-3 col-xl-2 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white border p-3 rounded-3 mb-4 w-100 text-center">
                    <img
                      src={require("../../img/radiology.png")}
                      alt=""
                      width="44"
                    />
                    <h6 class="mt-3 mb-0">Radiology </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="text-center">
                <a
                  href="javascript:void(0)"
                  title="Sign Up"
                  class="btn btn-primary"
                  onClick={() => navigate("/register")}
                >
                  Join Surgic Share <i class="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="py-5 bg-light">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <p class="title-meta mt-0 mb-0">
                  What We Provide <span class="line"></span>
                </p>
                <h2 class="h3 mb-2">Our Services</h2>
                <p>Explore our services and offerings.</p>
              </div>
            </div>
            <div class="row d-flex align-items-stretch">
              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white  p-4 rounded-3 mb-4 w-100 text-start">
                    <img
                      src={require("../../img/icon-live-surgery.png")}
                      alt=""
                      width=""
                    />
                    <h4 class="mt-3 h5">
                      Schedule/ Attend uninterrupted CME on Zoom{" "}
                    </h4>
                    <p class="mb-0">
                      {" "}
                      Organize or attend Uninterrupted CME on Zoom, anytime with
                      your Association/group members-free of cost.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white  p-4 rounded-3 mb-4 w-100 text-start">
                    <img
                      src={require("../../img/icon-meeting.png")}
                      alt=""
                      width=""
                    />
                    <h4 class="mt-3 h5">Organize / Attend Live Surgery </h4>
                    <p class="mb-0">
                      {" "}
                      Organize hassle free and cost effective Live surgery.
                      Attend live surgery of dream surgeons across globe,
                      sitting at home.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white  p-4 rounded-3 mb-4 w-100 text-start">
                    <img
                      src={require("../../img/icon-video.png")}
                      alt=""
                      width=""
                    />
                    <h4 class="mt-3 h5">
                      Post/Browse Surgery Video/ Tutorials{" "}
                    </h4>
                    <p class="mb-0">
                      {" "}
                      Post your unique surgery videos and tutorials or watch
                      surgery videos and tutorials of Dream surgeon or Dream
                      institute.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white  p-4 rounded-3 mb-4 w-100 text-start">
                    <img
                      src={require("../../img/icon-tutorial.png")}
                      alt=""
                      width=""
                    />
                    <h4 class="mt-3 h5">Post/ Attend Observership course </h4>
                    <p class="mb-0">
                      {" "}
                      Post your upcoming Observership course OR learn unique
                      surgical technique by attending Observership course from
                      expert surgeons.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white  p-4 rounded-3 mb-4 w-100 text-start">
                    <img
                      src={require("../../img/icon-workshop.png")}
                      alt=""
                      width=""
                    />
                    <h4 class="mt-3 h5">
                      Post/ Register Upcoming Conference/Workshop/Hands on
                      training
                    </h4>
                    <p class="mb-0">
                      {" "}
                      Post / browse for upcoming conferences, workshop or Hands
                      on training of your institution/ association in your area
                      of interest.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white  p-4 rounded-3 mb-4 w-100 text-start">
                    <img
                      src={require("../../img/icon-users.png")}
                      alt=""
                      width="44"
                    />
                    <h4 class="mt-3 h5">
                      Create your Patient Referral Profile & Refer Your Patient{" "}
                    </h4>
                    <p class="mb-0">
                      {" "}
                      Create your patient referral profile to get patient
                      referral from across surgical specialty. Refer your
                      patients to surgeons across surgical specialty, based on
                      availability of surgeon.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white  p-4 rounded-3 mb-4 w-100 text-start">
                    <img
                      src={require("../../img/icon-tutorial.png")}
                      alt=""
                      width=""
                    />
                    <h4 class="mt-3 h5">My Association My Group </h4>
                    <p class="mb-0">
                      Create open/ closed Association/ group of surgeons at
                      different level to do variety of academic activity within
                      group with a great comfort.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
                <div class="d-flex align-items-stretch w-100">
                  <div class="bg-white  p-4 rounded-3 mb-4 w-100 text-start">
                    <img
                      src={require("../../img/icon-meeting.png")}
                      alt=""
                      width=""
                    />
                    <h4 class="mt-3 h5">
                      Telementorship, Mentorship & Online Classes{" "}
                    </h4>
                    <p class="mb-0">
                      {" "}
                      Request for paid Telementorhip, Mentorship, online classes
                      from expert dream surgeons across globe.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="text-center">
                <a
                  href="javascript:void(0)"
                  title="Sign Up"
                  class="btn btn-primary"
                  onClick={() => navigate("/register")}
                >
                  Join Surgic Share <i class="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Gallery */}

        <section class="py-5 bg-primary">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <p class="title-meta mt-0 mb-0">
                  Photos and Videos <span class="line"></span>
                </p>
                <h2 class="h4 mb-2 text-white">Our Gallery</h2>
                <p class="text-light">
                  Latest photos from Surgicshare presentation and events.
                </p>
              </div>
            </div>
            <div class="row d-flex align-items-stretch new_gal">
              {galeryArray.length > 0
                ? galeryArray.map((val, index) => (
                    <div class="col-6 col-sm-4 col-lg-3 col-xl-2">
                      <a
                        href={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val.photo}`}
                        data-lightbox="gallery"
                        data-title={val.title}
                        class="mb-4 d-inline-block"
                      >
                        <img
                          src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val.photo}`}
                          class="img-fluid rounded-3"
                          style={{ height: "200px", width: "200px" }}
                        />
                      </a>
                    </div>
                  ))
                : null}
            </div>

            <div class="col-12">
              <div class="text-center">
                <Link to="/gallery" title="View more" class="btn btn-light">
                  View More Photos <i class="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Gallery */}

        {/* Extra.... */}

        {/* <section className="py-5 bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="title-meta mt-0 mb-0">
              Photos and Videos <span className="line"></span>
            </p>
            <h2 className="h4 mb-2 text-white">Our Gallery</h2>
            <p className="text-light">
              Latest photos from Surgicshare presentation and events.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Carousel>
              {chunks.map((chunk, index) => (
                <Carousel.Item key={index}>
                  <div className="d-flex justify-content-between">
                    {chunk.map((val, innerIndex) => (
                      <div
                        className="mb-4 d-inline-block"
                        style={{ margin: '0 5px' }}
                        key={innerIndex}
                      >
                        <a
                          href={`https://d27m4xh8yh8co7.cloudfront.net/${appStage}${val.photo}`}
                          // to="gallery-details"
                          data-lightbox="gallery"
                          data-title={val.title}
                        >
                          <img
                            src={`https://d27m4xh8yh8co7.cloudfront.net/${appStage}${val.photo}`}
                            sm={6}
                            lg={6}
                            xl={3}
                            className="img-fluid rounded-3 d-flex align-items-stretch"
                            style={{ height: '200px', width: '200px' }}
                            alt={val.title}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div class="col-12">
              <div class="text-center">
                <Link
                  to="/gallery"
                  title="View more"
                  class="btn btn-light"
                >
                  View More Photos <i class="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
        </div>
      </div>
    </section> */}

        {/* Extra.... */}
      </>
    </PublicRoot>
  );
};

export default Home;
